
<template>
    <div class="tabs-contents">
        <div class="tabs-content active">
            <div class="fs-row bootstrap-fix widgets">
                <div class="row tgju-widgets-row">
                    <div class="tgju-widgets-block col-12 col-lg-6 col-xl-4">
                        <div class="tgju-widget light has-icon last-trade-widget">
                            <div class="tgju-widget-title dot m-0">
                                <h3>آخرین معامله</h3>
                                <span class="tgju-widget-up-i"><span data-col="info.time" :data-val="stock_summary.info.time">{{stock_summary.info.time}}</span> <i
                                        class="fa fa-clock-o" aria-hidden="true"></i></span>
                            </div>
                            <div class="tables-default normal tables-border-bt-none">
                                <table class="table table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th class="text-left">خصیصه</th>
                                            <th class="text-right">مقادیر</th>
                                            <th class="text-left">خصیصه</th>
                                            <th class="text-right">مقادیر</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-left">بازگشایی</td>
                                            <td class="text-right">
                                                <span class="cell"><span dir="ltr" data-col="info.PriceFirst"
                                                        data-val="2164">{{ stock_summary.info.PriceFirst }}</span></span>
                                            </td>
                                            <td class="text-left">پایانی</td>
                                            <td class="text-right">
                                                <span class="cell"><span dir="ltr" data-col="info.last_price.PClosing"
                                                        data-val="2158">{{ stock_summary.info.last_price.PClosing }}</span></span>
                                                <span class="cell color-red"><span dir="ltr"
                                                        data-col="info.last_price.closing_change" data-val="8"><span
                                                            class="change change-up">{{ stock_summary.info.last_price.closing_change }}</span></span></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">بیشترین</td>
                                            <td class="text-right"><span class="high color-green badge-green" dir="ltr"
                                                    data-col="info.day_range.PriceMax" data-val="2234">{{ stock_summary.info.day_range.PriceMax }}</span></td>
                                            <td class="text-left">کمترین</td>
                                            <td class="text-right"><span class="low color-red badge-red" dir="ltr"
                                                    data-col="info.day_range.PriceMin" data-val="2086">{{ stock_summary.info.day_range.PriceMax }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">حجم</td>
                                            <td class="text-right"><span dir="rtl" data-col="trades.QTotTran5J"
                                                    data-val="1300000000">{{ stock_summary.trades.QTotTran5J }} <span
                                                        class="currency-type">میلیارد</span></span></td>
                                            <td class="text-left">دفعات</td>
                                            <td class="text-right"><span dir="ltr" data-col="trades.ZTotTran"
                                                    data-val="25631">{{ stock_summary.trades.ZTotTran }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">حد قیمت</td>
                                            <td class="text-right">
                                                <span dir="ltr">
                                                    <span data-col="info.gheymat_mojaz.PSGelStaMin"
                                                        data-val="2045">{{ stock_summary.info.gheymat_mojaz.PSGelStaMin }}</span> - <span
                                                        data-col="info.gheymat_mojaz.PSGelStaMax"
                                                        data-val="2259">{{ stock_summary.info.gheymat_mojaz.PSGelStaMax }}</span>
                                                </span>
                                            </td>
                                            <td class="text-left">ارزش</td>
                                            <td class="text-right"><span dir="rtl" data-col="trades.QTotCap"
                                                    data-val="2660100000000">{{ stock_summary.trades.QTotCap }} <span class="currency-type">هزار
                                                        میلیارد</span></span></td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">حجم مبنا</td>
                                            <td class="text-right"><span dir="rtl" data-col="info.BaseVol"
                                                    data-val="65753000">{{ stock_summary.info.BaseVol }} <span
                                                        class="currency-type">میلیون</span></span>
                                            </td>
                                            <td class="text-left">حد حجم</td>
                                            <td class="text-right">
                                                <span dir="ltr">
                                                    <span data-col="etc.range_hajm_min" data-val="1">{{ stock_summary.etc.range_hajm_min }}</span> - <span
                                                        data-col="etc.range_hajm_max" data-val="400000">{{ stock_summary.etc.range_hajm_max }}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="stocks-block-icon-bg"><i class="fa fa-bar-chart"></i></div>
                        </div>
                    </div>
                    <div class="tgju-widgets-block col-12 col-lg-6 col-xl-4">
                        <div class="tgju-widget light has-icon">
                            <div class="tgju-widget-title dot m-0">
                                <h3>معاملات حقیقی و حقوقی</h3>
                                <span class="tgju-widget-up-i"><span data-col="info.time" :data-val="stock_summary.info.time">{{stock_summary.info.time}}</span> <i
                                        class="fa fa-clock-o" aria-hidden="true"></i></span>
                            </div>
                            <div class="tables-default normal tables-border-bt-none">
                                <table class="table mini-mobile-table table-hover text-center">
                                    <thead class="text-center">
                                        <tr>
                                            <th>حقیقی</th>
                                            <th></th>
                                            <th>حقوقی</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span dir="ltr" data-col="trades.buy_and_sell.Buy_CountI" data-val="4568">{{ stock_summary.trades.buy_and_sell.Buy_CountI }}</span>
                                            </td>
                                            <td><span class="badge-lite">تعداد خریدار</span></td>
                                            <td>
                                                <span dir="ltr" data-col="trades.buy_and_sell.Buy_CountN" data-val="17">{{ stock_summary.trades.buy_and_sell.Buy_CountN }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span dir="rtl" data-col="trades.buy_and_sell.Buy_I_Volume" data-val="701702000">{{ stock_summary.trades.buy_and_sell.Buy_I_Volume }}
                                                    <span class="currency-type">میلیون</span></span>
                                            </td>
                                            <td><span class="badge-lite">حجم خرید</span></td>
                                            <td>
                                                <span dir="rtl" data-col="trades.buy_and_sell.Buy_N_Volume" data-val="54620000">{{ stock_summary.trades.buy_and_sell.Buy_N_Volume }} <span
                                                        class="currency-type">میلیون</span></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span class="color-green badge-green" dir="ltr" data-col="trades.buy_and_sell.Sell_CountI"
                                                    data-val="4258">{{ stock_summary.trades.buy_and_sell.Sell_CountI }}</span>
                                            </td>
                                            <td><span class="badge-lite">تعداد فروشنده</span></td>
                                            <td>
                                                <span dir="ltr" data-col="trades.buy_and_sell.Sell_CountN" data-val="23">{{ stock_summary.trades.buy_and_sell.Sell_CountN }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span dir="rtl" data-col="trades.buy_and_sell.Sell_I_Volume" data-val="703997000">{{ stock_summary.trades.buy_and_sell.Sell_I_Volume }}
                                                    <span class="currency-type">میلیون</span></span>
                                            </td>
                                            <td><span class="badge-lite">حجم فروش</span></td>
                                            <td>
                                                <span dir="rtl" data-col="trades.buy_and_sell.Sell_N_Volume" data-val="52325000">{{ stock_summary.trades.buy_and_sell.Sell_N_Volume }}
                                                    <span class="currency-type">میلیون</span></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span dir="rtl" data-col="etc.taghir_malekiat_haghighi" data-val="-2295000"><span
                                                        class="change change-down">{{ stock_summary.etc.taghir_malekiat_haghighi }} <span
                                                            class="currency-type">میلیون</span></span></span>
                                            </td>
                                            <td><span class="badge-lite">تغییر مالکیت</span></td>
                                            <td>
                                                <span dir="rtl" data-col="etc.taghir_malekiat_hoghoghi" data-val="2295000"><span
                                                        class="change change-up">{{ stock_summary.etc.taghir_malekiat_hoghoghi }} <span
                                                            class="currency-type">میلیون</span></span></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="stocks-block-icon-bg"><i class="fa fa-bar-chart"></i></div>
                        </div>
                    </div>
                    <div class="tgju-widgets-block col-12 col-lg-6 col-xl-4">
                        <div class="tgju-widget light has-icon ask-and-bid-widget">
                            <div class="tgju-widget-title dot m-0">
                                <h3>عرضه و تقاضا</h3>
                                <span class="tgju-widget-up-i"><span data-col="info.time" :data-val="stock_summary.info.time">{{stock_summary.info.time}}</span> <i
                                        class="fa fa-clock-o" aria-hidden="true"></i></span>
                            </div>
                            <div class="tables-default normal tables-border-bt-none">
                                <table class="table table-hover mini-mobile-table text-center stocks-deals-table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>دستور</th>
                                            <th>تعداد</th>
                                            <th>خرید</th>
                                            <th>فروش</th>
                                            <th>تعداد</th>
                                            <th>دستور</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="1">{{ stock_summary.demands[1][1] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="0">{{ stock_summary.demands[1][0] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                    data-id="2">{{ stock_summary.demands[1][2] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                    data-id="3">{{ stock_summary.demands[1][3] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="5">{{ stock_summary.demands[1][5] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="4">{{ stock_summary.demands[1][4] }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="1">{{ stock_summary.demands[2][1] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="0">{{ stock_summary.demands[2][0] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                    data-id="2">{{ stock_summary.demands[2][2] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                    data-id="3">{{ stock_summary.demands[2][3] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="5">{{ stock_summary.demands[2][5] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="4">{{ stock_summary.demands[2][4] }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="1">{{ stock_summary.demands[3][1] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="0">{{ stock_summary.demands[3][0] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                    data-id="2">{{ stock_summary.demands[3][2] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                    data-id="3">{{ stock_summary.demands[3][3] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="5">{{ stock_summary.demands[3][5] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="4">{{ stock_summary.demands[3][4] }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="1">{{ stock_summary.demands[4][1] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="0">{{ stock_summary.demands[4][0] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                    data-id="2">{{ stock_summary.demands[4][2] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                    data-id="3">{{ stock_summary.demands[4][3] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="5">{{ stock_summary.demands[4][5] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="4">{{ stock_summary.demands[4][4] }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="1">{{ stock_summary.demands[5][1] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="0">{{ stock_summary.demands[5][0] }}</span></td>
                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                    data-id="2">{{ stock_summary.demands[5][2] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                    data-id="3">{{ stock_summary.demands[5][3] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                    data-id="5">{{ stock_summary.demands[5][5] }}</span></td>
                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                    data-id="4">{{ stock_summary.demands[5][4] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="stocks-block-icon-bg"><i class="fa fa-bar-chart"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'StockSummary',
    props: ['data'],
    data() {
        return {
            stock_summary: this.data,
        }
    },
    mounted() {
    },

    methods: {
    },
}
</script>

<style>

</style>